@import 'colors.scss';

%button {
    border-radius: 6px;
    width: unset;
    height: 38px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    padding: 10.5px 17.5px;
    border: none;
}

.p-button-primary {
    @extend %button;
    background-color: $info;
}
