@import 'colors.scss';

.ag-tab-menu {
    .p-tabmenu {
        display: block;
        width: 378px;
    }
    .p-menuitem-text {
        text-transform: uppercase;
        color: $main;
        font-weight: 700;
    }
    .p-menuitem-link-active {
        border-color: $info !important;
        .p-menuitem-text {
            color: $info;
        }
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
        box-shadow: unset;
    }
}
