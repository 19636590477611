$content-padding: 12px;

.ag-request-creation-page-layout {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-areas: 'header' 'main';
    header {
        grid-area: header;
    }
    main {
        grid-area: main;
        padding: $content-padding $content-padding 0 $content-padding;
        display: flex;
        flex-direction: column;
        gap: $content-padding;
        .stepper {
            width: 100%;
        }
        .content {
            width: 100%;
            flex-grow: 1;
            app-helios-worksite-prestation {
                display: block;
                max-height: calc(100% - 70px);
                height: 100%;
            }
        }
    }

    .card {
        margin-bottom: 0;
    }
}
