$content-padding: 12px;

.ag-table-page-layout {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto minmax(0, 1fr) auto;
    grid-template-areas: 'header' 'main' 'footer';
    header {
        grid-area: header;
    }
    main {
        grid-area: main;
        padding: $content-padding $content-padding 0 $content-padding;
    }
    footer {
        grid-area: footer;
        padding: 0 $content-padding $content-padding $content-padding;
    }
}
