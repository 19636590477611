@import './assets/theme/denim/theme-light.css';
@import './assets/layout/css/layout-light.css';

/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system

:root {
    --main: #3c4962;
    --active: #3b82f6;
    --danger: #fe0001;
    --stroke: #dee2e6;
    --blue-500: #404e68;
    --hover: #f6faff;
    --error: #f44336;
}

@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';

// AGI-LOG generic styles:
@import '../node_modules/leaflet/dist/leaflet.css';

@import './styles/forms.scss';
@import './styles/containers.scss';
@import './styles/layouts.scss';
@import './styles/tables.scss';
@import './styles/table-page-layout.scss';
@import './styles/request-creation-page-layout.scss';
@import './styles/clear-filter.scss';
@import './styles/tooltip.scss';
@import './styles/details.scss';
@import './styles/titles.scss';
@import './styles/autocomplete.scss';
@import './styles/dialog.scss';
@import './styles/tab-menu.scss';
@import './styles/carto.scss';
@import './styles/timeline.scss';
@import './styles/chips.scss';
@import './styles/buttons.scss';
