@import 'colors.scss';
.upload-dialog {
    .p-dialog {
        border-radius: 10px;
        .p-dialog-header {
            padding: 5px 15px;
            border-bottom: 1px solid $light-gray;
            border-radius: 10px 10px 0 0;
            .p-dialog-title {
                font-weight: 700;
                font-size: 16px;
            }
        }
        .p-dialog-content {
            padding: 15px;
            border-radius: 0 0 10px 10px;
        }
    }
}

.select-line-dialog,
.no-header {
    .p-dialog-header {
        display: none !important;
    }
    .p-dialog-content {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 0;
        overflow: hidden;
    }
}

.common-modal {
    .p-dialog-header {
        padding-block: 7px;
        border-bottom: solid 1px rgba(#000, 0.1);
    }
}
