.p-chips-token {
    background: #8a99b7 !important;
    border-radius: 6px !important;
}
.p-chips {
    .p-inputtext,
    .p-inputtext.p-chips-multiple-container.p-focus {
        border: none !important;
        box-shadow: none;
    }
}
