@import 'colors.scss';

.ag-clear-filter-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid $light-gray;
    background: url('/assets/images/page-title/clear-filter-black-icon.svg') no-repeat center;
    &:hover {
        background: url('/assets/images/page-title/clear-filter-white-icon.svg') no-repeat center;
        background-color: $info;
        border: 1px solid $info;
    }
}
