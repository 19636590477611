@import 'colors.scss';
@import 'animation.scss';

$position-marker-size: 35px;

$inner-circle-padding: 5px;
$inner-circle-size: calc($position-marker-size + 2 * $inner-circle-padding);

$outer-circle-padding: 10px;
$outer-circle-size: calc($position-marker-size + 2 * $outer-circle-padding);

.leaflet-marker-icon {
    background-color: transparent !important;
}

.current-position-marker {
    width: $position-marker-size;
    height: $position-marker-size;
    position: relative;
    &-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $main-300;
        width: $position-marker-size;
        height: $position-marker-size;
        position: relative;
        z-index: 2;
    }
    .circle {
        position: absolute;
        border-radius: 50%;
        background-color: $main-300;
    }
    .inner-circle {
        opacity: 0.2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $position-marker-size;
        height: $position-marker-size;
        animation: inner-circle 1.8s infinite ease-in-out;
        animation-delay: 1.4s;
    }
    .outer-circle {
        opacity: 0.2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $position-marker-size;
        height: $position-marker-size;
        animation: outer-circle 1.8s infinite ease-in-out;
    }
}

$gap: 7px;

.marker-popup {
    display: flex;
    flex-direction: column;
    gap: $gap;
    .name {
        color: $main;
        font-size: 13px;
        font-weight: 600;
    }
    .events {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: $gap;
        .event {
            display: flex;
            flex-wrap: nowrap;
            gap: $gap;
            align-items: center;
            margin: 0;
            &.planned {
                opacity: 0.5;
            }
            .icon {
                width: 20px;
                text-align: center;
            }
            .date {
                font-size: 12px;
                font-weight: 400;
            }
            .title {
                font-size: 13px;
                font-weight: 600;
            }
        }
    }
}

.planned-marker {
    opacity: 0.6;
}

@include pulse(inner-circle, $position-marker-size, $outer-circle-size);
@include pulse(outer-circle, $position-marker-size, $outer-circle-size);
