@import 'colors';

$content-padding: 12px;
.ag-layout {
    width: 100%;
    display: flex;
    flex-direction: column;

    header {
        h4 {
            margin: 1.5rem 0;
        }
    }

    main {
        padding: $content-padding;
        height: calc(100vh - 120px);
    }
}

.error-large-image {
    border: solid 2px $danger;
}
