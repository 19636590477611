@mixin pulse($name, $fromSize, $toSize, $opacity: 0.8) {
    @keyframes #{$name} {
        0% {
            width: $fromSize;
            height: $fromSize;
            opacity: $opacity;
        }
        100% {
            width: $toSize;
            height: $toSize;
            opacity: 0;
        }
    }
}
