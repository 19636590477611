.ag-title {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 25px;
    .left-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $light-charcoal;
        width: 30px;
        height: 30px;
    }
    h3 {
        margin: 0;
        padding: 0;
        line-height: unset;
        font-size: 16px;
        font-weight: 700;
    }
    .right-icon {
        margin-left: auto;
        width: 30px;
        height: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .searchbar {
        margin-left: auto;
    }
}
