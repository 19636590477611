.text-open-sans-46-bold {
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 44px;
}

.text-open-sans-32-bold {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
}

.text-open-sans-24-medium {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
}

.text-open-sans-14-regular {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.text-open-sans-14-bold {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.text-open-sans-14-bolder {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.text-open-sans-16-bold {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text-open-sans-24-bold {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.text-open-sans-13-regular {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.text-open-sans-13-medium {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.text-open-sans-13-bold {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
.text-open-sans-12-regular {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.text-open-sans-12-bold {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
