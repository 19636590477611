@import 'colors.scss';
@import 'texts.scss';

$gray-label: #757575;

.ag-detail-layout {
    padding: 12px;
    border-radius: 6px;
}

.ag-detail-content {
    .row {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        margin-bottom: 20px;
        label {
            @extend .text-open-sans-13-regular;
            color: $gray-label;
        }
        div {
            @extend .text-open-sans-13-bold;
            color: $main;
        }
    }
}
