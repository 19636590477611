@import 'colors.scss';

.p-tooltip {
    text-align: center;
    border-radius: 5px;
    z-index: 10010 !important;
}
.p-tooltip .p-tooltip-text {
    background-color: $dark-gray !important;
    color: white;
    text-align: left;
}
.p-tooltip-arrow {
    width: 4px !important;
}
