$left-right-point-margin: 63px;

.p-timeline-event-connector {
    background-color: white;
    border-bottom: 1px solid #3c4962;
}
.planned-next + .p-timeline-event-connector {
    background-color: white;
    border-bottom: 1px dashed #a9aaab;
}
.p-timeline-event-content {
    white-space: nowrap;
}
.p-timeline-event-content,
.p-timeline-event-opposite {
    width: 160px;
    margin-left: -$left-right-point-margin;
    text-align: center;
}
