@import 'colors.scss';

.p-autocomplete-token {
    background: $main-100 !important;
    .p-autocomplete-token-icon {
        display: inline-flex;
    }
}
.p-autocomplete {
    width: 100%;
}
